<template>
  <div class="page BigData">
    <a-button type="primary" style="margin-bottom: 15px" @click="handleAdd"
      >添加</a-button
    >
    <a-table
      :dataSource="data"
      :columns="columns"
      rowKey="id"
      :pagination="{
        page: page,
        total: total,
        pageSize: 3,
      }"
      @change="pageChange"
    >
      <template #age="{ record }">
        {{ record.taskNum - record.taskLeftNum }}
      </template>
      <template #status="{ record }">
        {{ handleStatus(record.status) }}
      </template>
      <template #operation="{ record }">
        <a-button size="small" type="primary" @click="start(record.id)"
          >启用</a-button
        >
        <a-button style="margin-left: 4px" type="primary" size="small"
          >续费</a-button
        >
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      title="支付结果"
      centered
      :maskClosable="false"
      okText="支付成功"
      cancelText="支付失败"
      @ok="payRreult"
      @cancel="payRreult"
    >
      是否支付成功？
    </a-modal>
    <a-modal
      v-model:visible="visibleAdd"
      title="选择套餐"
      :maskClosable="false"
      centered
      okText="确定"
      cancelText="取消"
      class="taocan"
      @ok="handlePay"
    >
      <a-form>
        <a-form-item label="套餐选择">
          <!-- <div class="box"> -->
          <div
            class="box-item"
            :class="{ active: item.id === current }"
            v-for="item in dataSelect"
            :key="item.id"
            @click="handleClick(item.id)"
          >
            {{ item.name }}
            <img
              v-if="item.id === current"
              src="../../assets/select_money.png"
            />
          </div>
        </a-form-item>
        <a-form-item label="充值方式">
          <a-radio-group name="radioGroup" v-model:value="readio">
            <a-radio value="1">支付宝</a-radio>
            <!-- <a-radio value="2">微信</a-radio> -->
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { getList, getLogin, getPay } from "../../service/bigdata";
import { onMounted } from "@vue/runtime-core";
// import { AlipayCircleOutlined, WechatOutlined } from "@ant-design/icons-vue";
const columns = [
  {
    title: "账号",
    dataIndex: "username",
  },
  {
    title: "状态",
    dataIndex: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "绑定号码",
    dataIndex: "mobile",
  },
  {
    title: "套餐",
    dataIndex: "comboName",
  },
  {
    title: "已用",
    dataIndex: "age",
    slots: {
      customRender: "age",
    },
  },
  {
    title: "未用",
    dataIndex: "taskLeftNum",
  },
  {
    title: "账号有效期",
    dataIndex: "expiry_time",
  },
  {
    title: "操作",
    fixed: "right",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "BigData",
  // components: { AlipayCircleOutlined, WechatOutlined },
  setup() {
    const state = reactive({
      visible: false,
      visibleAdd: false,
      current: 1,
      dataSelect: [
        { id: 1, name: "套餐A-机器人套餐2仟5佰" },
        { id: 2, name: "套餐B机器人套餐5仟" },
        { id: 3, name: "套餐C-机器人套餐1万" },
        { id: 4, name: "套餐D-机器人套餐2万" },
        { id: 5, name: "套餐E-机器人套餐5万" },
      ],
      readio: "1",
      data: [],
      total: undefined,
      page: 1,
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        count: 3,
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const start = async (id) => {
      // state.visible = true;
      const res = await getLogin({
        access_token: sessionStorage.getItem("token"),
        id,
      });
      console.log(res);
      var a = document.createElement("a"); //创建a标签
      a.target = "_blank";
      a.href = res.data.data.url;
      // "&string=客户名称,电话号码"; //设置a标签的链接
      document.body.appendChild(a); //a标签添加到页面
      a.click(); //设置a标签触发单击事件
      document.body.removeChild(a); //移除a标签
    };
    const handleAdd = () => {
      state.visibleAdd = true;
    };
    const handleClick = (id) => {
      state.current = id;
    };
    const handleStatus = (num) => {
      switch (num) {
        case 1:
          return "正常";
        case 2:
          return "未激活";
        case 3:
          return "禁用";
        case 4:
          return "已过期";
      }
    };
    const pageChange = (e) => {
      state.page = e.current;
      init();
    };
    const handlePay = async () => {
      const res = await getPay({
        access_token: sessionStorage.getItem("token"),
        package_id: state.current,
      });
      console.log(res);
      const div = document.createElement("div");
      div.innerHTML = res.data.data;
      document.body.appendChild(div);
      const form = document.querySelector("#alipaysubmit");
      form.target = "_Blank";
      form.submit();
      div.innerHTML = ''
      state.visibleAdd = false;
      state.visible = true;
    };
    const payRreult = () => {
      state.visible = false;
      init();
    };
    return {
      columns,
      start,
      ...toRefs(state),
      handleAdd,
      handleClick,
      handleStatus,
      pageChange,
      handlePay,
      payRreult,
    };
  },
};
</script>
<style scoped>
.BigData {
  padding: 10px;
}
.box-item {
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
}
.box-item img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.taocan .ant-form {
  margin: 0 20px;
}
.taocan .ant-form-item:nth-last-child(1) {
  margin-bottom: 0;
}
.active {
  border: 1px solid #1890ff;
  color: #1890ff;
}
</style>