import http from "../axios/http"

//大数据A用户列表
export let getList = (params) => {
    return http.get("/data/a_index", { params })
}
//登录
export let getLogin = (params) => {
    return http.get("/data/a_login", { params })
}
//支付
export let getPay = (params) => {
    return http.get("/data/a_ali_web_pay", { params })
}
//导出记录
export let exportRecord = (params) => {
    return http.get("/data/data_record", { params })
}
